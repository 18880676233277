export enum DataHook {
  MainActionButton = 'main-action-button',
  CoverImage = 'cover-image',
  DetailsCardEarnPoints = 'details-card-earn-points',
  DetailsCardRedeemPoints = 'details-card-redeem-points',
  DetailsCardSignUp = 'details-card-sign-up',
  EmptyState = 'empty-state',
  ErrorState = 'error-state',
  Header = 'header',
  LoyaltyPageWrapper = 'loyalty-page-wrapper',
  MyRewardsWrapper = 'MyRewards-wrapper',
  ProgramName = 'program-name',
  ProgramTitle = 'program-title',
  TiersProgram = 'tiers-program',
  LoyaltyWidget = 'loyalty-widget',
  AddBirthdayModal = 'add-birthday-modal',
  AddBirthdayButton = 'add-birthday-button',
  AddBirthdayDatePicker = 'add-birthday-date-picker',
  // Loyalty Page Settings
  SettingsTabs = 'settings-tabs',
  SettingsDesignDetails = 'settings-design-details',
  SettingsDesignHeader = 'settings-design-header',
  SettingsDesignTiers = 'settings-design-tiers',
  SettingsDesignCurrentTier = 'settings-design-current-tier',
  SettingsDesignTab = 'settings-design-tab',
  SettingsDesignTabButton = 'settings-design-tab-button',
  SettingsDisplayTab = 'settings-display-tab',
  SettingsDisplayTabButton = 'settings-display-tab-button',
  SettingsLayoutTab = 'settings-layout-tab',
  SettingsLayoutTabButton = 'settings-layout-tab-button',
  SettingsLayoutTabHeaderSection = 'settings-layout-tab-header-section',
  SettingsLayoutTabProgramDetailsSection = 'settings-layout-tab-program-details-section',
  SettingsLayoutTabTiersSection = 'settings-layout-tab-tiers-section',
  SettingsTextTab = 'settings-text-tab',
  SettingsTextTabButton = 'settings-text-tab-button',
  SettingsTextTabHeaderSection = 'settings-text-tab-header-section',
  SettingsTextTabProgramDetailsSection = 'settings-text-tab-program-details-section',
  SettingsTextTabTiersSection = 'settings-text-tab-tiers-section',
  // Status Widget
  StatusWidgetSettingsTabLayout = 'status-widget-settings-tab-layout',
  StatusWidgetSettingsDisplayTab = 'status-widget-settings-display-tab',
  StatusWidgetSettingsLayoutTab = 'status-widget-settings-layout-tab',
  StatusWidgetSettingsDesignTab = 'status-widget-settings-design-tab',
  StatusWidgetSettingsDesignTabHover = 'status-widget-settings-design-tab-hover',
  StatusWidgetSettingsTextTab = 'status-widget-settings-text-tab',
  StatusWidgetSettingsTextTabLoggedOut = 'status-widget-settings-text-tab-logged-out',
  StatusWidget = 'status-widget',
}
