import {
  loyaltyJoinNowOrViewPoints,
  loyaltyWidgetEditorSettingsChangeSrc136Evid332,
  loyaltyWidgetEditorSettingsChangeTabSrc136Evid331,
  loyaltyWidgetEditorSettingsOpenSrc136Evid330,
  loyaltyWidgetModalInEditorActionSrc136Evid333,
} from '@wix/bi-logger-loyalty-data/v2';
import { useBi as useBiLogger } from '@wix/yoshi-flow-editor';

import { WidgetId } from '../../../constants';

interface BiChangeSettingsParams {
  tabName: string;
  fieldName: string;
  newSettings: object;
}

export const useBi = () => {
  const bi = useBiLogger();

  const biOpenSettings = async () => {
    await bi.report(
      loyaltyWidgetEditorSettingsOpenSrc136Evid330({
        widget_id: WidgetId.LoyaltyStatus,
      }),
    );
  };

  const biChangeSettingsTab = async (tabName: string, state?: string) => {
    await bi.report(
      loyaltyWidgetEditorSettingsChangeTabSrc136Evid331({
        widget_id: WidgetId.LoyaltyStatus,
        tabName: state ? `${tabName}-${state}` : tabName,
      }),
    );
  };

  const biChangeSettings = async ({ tabName, fieldName, newSettings }: BiChangeSettingsParams) => {
    await bi.report(
      loyaltyWidgetEditorSettingsChangeSrc136Evid332({
        widget_id: WidgetId.LoyaltyStatus,
        tabName,
        fieldName,
        value: JSON.stringify(newSettings),
      }),
    );
  };

  const biResetStyles = async (tabName: string, stylesToReset: string[]) => {
    await bi.report(
      loyaltyWidgetEditorSettingsChangeSrc136Evid332({
        widget_id: WidgetId.LoyaltyStatus,
        tabName,
        fieldName: 'resetToOriginalDesign',
        value: JSON.stringify({ stylesToReset }),
      }),
    );
  };

  const biAddWidgetPanel = async (action: 'modal_opened' | 'modal_canceled' | 'add_widget', openOrigin?: string) => {
    if (!bi.report) {
      return;
    }
    await bi.report(
      loyaltyWidgetModalInEditorActionSrc136Evid333({
        action,
        open_origin: openOrigin,
      }),
    );
  };

  const biClickOnWidget = async (isLoggedIn: boolean, userRole?: string) => {
    await bi.report(
      loyaltyJoinNowOrViewPoints({
        button: isLoggedIn ? 'points_widget' : 'join_now',
        role: userRole,
        widget_id: WidgetId.LoyaltyStatus,
      }),
    );
  };

  return {
    biOpenSettings,
    biChangeSettingsTab,
    biChangeSettings,
    biResetStyles,
    biAddWidgetPanel,
    biClickOnWidget,
  };
};
