import { WidgetProps } from '@wix/yoshi-flow-editor';
import React, { FC } from 'react';

import { StatusButton, WidgetContextProvider } from './components';
import { ControllerProps } from './types';

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  return (
    <WidgetContextProvider controllerProps={props}>
      <StatusButton />
    </WidgetContextProvider>
  );
};

export default Widget;
