import { useSettings } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { useNumberFormatter } from '../../../../hooks';
import settingsParams, { DisplayChoice } from '../../settingsParams';
import { useControllerProps } from './useControllerProps';

export const useWidgetText = (): string => {
  const { loyaltyProgram, pointsBalance, rewardsAvailable, isLoggedIn, locale } = useControllerProps();
  const { formatNumber } = useNumberFormatter(locale);
  const settings = useSettings();
  const { t } = useTranslation();

  if (!isLoggedIn) {
    const beforeLoginText = settings.get(settingsParams.beforeLoginLabel);
    return beforeLoginText;
  }

  const displayType = settings.get(settingsParams.displayChoice);

  if (displayType === DisplayChoice.PointsBalance) {
    const pointsBalanceText = settings.get(settingsParams.pointsBalanceLabel);
    const points = formatNumber(pointsBalance);
    return `${pointsBalanceText}: ${points}`;
  }

  if (displayType === DisplayChoice.Rewards) {
    const rewardsText = settings.get(settingsParams.rewardsAvailableLabel);
    const rewardsCount = formatNumber(rewardsAvailable);
    return `${rewardsText}: ${rewardsCount}`;
  }

  const programName: string =
    settings.get(settingsParams.programNameLabel) ||
    loyaltyProgram.name ||
    t('status-widget.program-name.default-text');

  return programName;
};
