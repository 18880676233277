import React, { PropsWithChildren } from 'react';

import { ControllerPropsContext } from '../contexts';
import { ControllerProps } from '../types';

interface WidgetContextProviderProps extends PropsWithChildren {
  controllerProps: ControllerProps;
}

export const WidgetContextProvider: React.FC<WidgetContextProviderProps> = ({ controllerProps, children }) => {
  return <ControllerPropsContext.Provider value={controllerProps}>{children}</ControllerPropsContext.Provider>;
};
